import React from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '~/config';
import * as Styled from './styles';

export const Menu: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.MenuNav>
      <Styled.Link to={ROUTES.whoWeAre}>{t('Links.aboutUs')}</Styled.Link>
      <Styled.Link to={ROUTES.collaborators}>
        {t('Links.collaborators')}
      </Styled.Link>
      <Styled.Link to={ROUTES.terms}>
        {t('Links.termsAndConditions')}
      </Styled.Link>
      <Styled.ExternalLink to="https://www.health.harvard.edu/diseases-and-conditions/coronavirus-resource-center">
        {t('Links.covidResources')}
      </Styled.ExternalLink>
      <Styled.ExternalLink to="https://www.cdc.gov/flu/index.htm">
        {t('Links.influenzaResources')}
      </Styled.ExternalLink>
      <Styled.Link to={ROUTES.rapidCovidTestingSurvey}>
        {t('Links.rapidCovidTestingSurvey')}
      </Styled.Link>
      <Styled.Link to={ROUTES.faq}>{t('Links.faq')}</Styled.Link>
      <Styled.Link to={ROUTES.privacyPolicy}>
        {t('Links.privacyPolicy')}
      </Styled.Link>
      <Styled.Link to={ROUTES.reports}>{t('Links.reports')}</Styled.Link>
      <Styled.ExternalLink to="mailto:info@outbreaksnearme.org">
        {t('Footer.contactUs')}
      </Styled.ExternalLink>
    </Styled.MenuNav>
  );
};

import React, { useEffect } from 'react';
import * as Styled from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import loadable from '@loadable/component';
import { MapFilters } from '~/components/MapFilters/index.tsx';
import { MapStatsForm } from '~/components/forms/MapStatsForm/index.tsx';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useZipCode } from '~/components/forms/MapStatsForm/useZipCode.ts';
import { Banner } from '~/components/Banner/index.tsx';
import { ROUTES } from '~/config';

const Map = loadable(() => import('~/components/Map/index.tsx'));

const MapSection = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { updateSearchZipCode } = useZipCode();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    return function cleanup() {
      updateSearchZipCode('');
    };
  });

  return (
    <Styled.MapSection>
      <Banner
        message={t('RapidTestBanner.message')}
        route={ROUTES.rapidCovidTestingSurvey}
        link={t('RapidTestBanner.link')}
      />
      <Container disableGutters={isMobile}>
        <Styled.Content id="goToMapTarget">
          <Grid item xs={12} md={8}>
            <Styled.MapStatsFormContainer>
              <Styled.MapStatsFormTitle>
                {t('Map.description')}
              </Styled.MapStatsFormTitle>
              <MapStatsForm />
            </Styled.MapStatsFormContainer>
            <Map />
            <Styled.MapboxCredit>
              {t('Footer.mapboxCredit')}
            </Styled.MapboxCredit>
          </Grid>
          <Grid item xs={12} md={4}>
            <MapFilters />
          </Grid>
        </Styled.Content>
      </Container>
    </Styled.MapSection>
  );
};

export default MapSection;
